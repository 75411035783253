import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react';
import ModalContentProps from '../components/modal/modalContent';
import './login.scss';
import {useAuth} from '../hooks/useAuth';
import NonPrivateRoute from '../components/private/nonprivate';

interface LoginProps extends ModalContentProps {
}

const Login: FC<LoginProps> = ({setWide}) => {
    const auth = useAuth();

    useEffect(() => setWide(false), []);
    const [formData, setFormData] = useState({username: '', password: ''});
    const [error, setError] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({...prevData, [name]: value}));
    }

    const handleLogin = async (event: FormEvent) => {
        event.preventDefault();
        const result = await auth.signIn(formData.username, formData.password);
        if (!result.success) {
            setError(true);
        }
    }

    return (
        <NonPrivateRoute>
            <div className='login'>
                <h1>Login</h1>
                <form onSubmit={handleLogin}>
                    <label>
                        <input type="text" name="username" placeholder="Username" onChange={handleChange}/>
                    </label>

                    <label>
                        <input type="password" name="password" placeholder="Password" onChange={handleChange}/>
                    </label>
                    {error && <p className="error">Login failed!</p>}
                    <button>Login</button>
                </form>
            </div>
        </NonPrivateRoute>)
};

export default Login;
