import {APIConfig} from "../config/api";
import {NavigateList} from "../components/navigate-list/navigate-list";
import ModalContentProps from "../components/modal/modalContent";
import React, {FC, useEffect} from "react";
import {BackButton} from "../components/header-button/back-button";

export interface NavigateDemoProps extends ModalContentProps {
}

export const NavigateDemo: FC<NavigateDemoProps> = ({setWide}) => {
    useEffect(() => setWide(true), []);
    const images = [{
        src: `${APIConfig.endpoint}/sem/images/0`,
        href: 'sem',
        title: 'SEM'
    }, {
        src: `${APIConfig.endpoint}/brightfield/images/0`,
        href: 'brightfield',
        title: 'Brightfield'
    }]
    return <>
        <div className={'header'}>
            <div className={'left'}>
                <BackButton></BackButton>
            </div>
            <div className={'center'}>

                <h2>Choose a demo</h2>
            </div>
            <div className={'right'}>

            </div>

        </div>
        <NavigateList images={images}/></>
}
