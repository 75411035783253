import React, {ReactNode, useCallback, useEffect, useMemo, useRef} from "react";
import styles from './simple-dialog.module.scss'
interface SimpleDialogProps {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
}
export const SimpleDialog = (props: SimpleDialogProps) => {
  return (
    <>
      {props.isOpen && (
        <div className="dialog-overlay" onClick={props.toggle}>
          <div onClick={(e) => e.stopPropagation()} className="dialog-box">
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}