import React, {FC} from "react";
import {EditParameter} from "./edit-parameter";

export interface FormValues {
    AspectRatio: number;
    MajorAxis: number;
    MinorAxis: number;
}

export interface EditParametersProps {
    values: FormValues;
    setFormValues: (values: FormValues) => void;
}

export const EditParameters: FC<EditParametersProps> = (params) => {
    const parameterOptions = {
        AspectRatio: {
            step: 0.1,
            min: 0.05,
            max: 2,
        },
        MajorAxis: {
            step: 1.0,
            min: 0,
            max: 500
        },
        MinorAxis: {
            step: 1.0,
            min: 0,
            max: 500
        }
    }
    return <>
        {(Object.keys(parameterOptions) as Array<keyof typeof parameterOptions>).map(key =>
            <EditParameter formOptions={parameterOptions[key]} element={key} key={key} {...params}/>
        )}

    </>
}