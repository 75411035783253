import ModalContentProps from "../components/modal/modalContent";
import React, {FC, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {APIConfig} from "../config/api";
import './result.scss';
import {BackButton} from "../components/header-button/back-button";
import {ImagePopup} from "../components/image-popup/image-popup";
import {SocketFrame} from "./socket-frame";


export interface ResultProps extends ModalContentProps {
}

export interface ResultNavigationOptions {
    name: string;
    indices: number[];
    alterable: unknown;
}

export const Result: FC<ResultProps> = ({setWide}) => {
    const navigate = useNavigate();
    const [key, setKey] = useState('');
    useEffect(() => setWide(true), []);
    const location: { state?: ResultNavigationOptions } = useLocation();
    const state = location.state ? location.state : JSON.parse(localStorage.getItem('result_state') || '{}');


    const [indices, setIndices] = useState(() => (new Set() as Set<number>));
    const addIndex = (index: number) => {
        setIndices(prev => new Set(prev).add(index))
    }

    const [src, setSrc] = useState('');
    const [modal, toggleModal] = useState(false);
    if (Object.keys(state).length > 0) localStorage.setItem('result_state', JSON.stringify(state));
    else {
        navigate('/')
        return <></>
    }
    ;
    return <>
        <SocketFrame {...state} addIndex={addIndex} setKey={setKey}/>
        <div className={'header'}>
            <div className={'left'}>
                <BackButton></BackButton>
            </div>
            <div className={'center'}>
                <h2>Results</h2>
            </div>
            <div className={'right'}>

            </div>
        </div>
        ({indices.size} / {state.indices.length} processed)<br/>
        <div className={'result-grid'}>
            {Array.from(indices).map(index =>
                <div key={index} className={'hover-wrapper'}>
                    <img src={`${APIConfig.endpoint}/output/${state.name}/${key}/${index}.bmp`} key={index}
                         alt={`${state.name} ${index}`}/>
                    <div className={'text-wrapper'} onClick={() => {
                        setSrc(`${APIConfig.endpoint}/output/${state.name}/${key}/${index}.bmp`)
                        toggleModal(true);
                    }}><p>View me</p></div>
                </div>
            )}
        </div>
        {modal && <ImagePopup url={src} alt={'Modal Image'} onClose={() => toggleModal(false)}/>}
    </>
}