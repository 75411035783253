import React, {FC} from "react";
import {IconButton, ImageList, ImageListItem, ImageListItemBar, Link} from "@mui/material";
import ArrowIcon from "@mui/icons-material/ArrowCircleRight";
import './navigate-list.scss'
import {useNavigate} from "react-router-dom";


export interface NavigateListProps {
    images: {
        src: string, title: string,
        subtitle?: string, rows?: number, cols?: number, href?: string
    }[]
}

function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export const NavigateList: FC<NavigateListProps> = ({images}) => {

    const navigate = useNavigate();
    const onClick = (href?: string) => {
        if (href) {
            if (href.startsWith('http')) {
                window.location.href = href;
            } else {
                navigate(href)
            }
        } else {
            return;
        }
    }
    return <div className={'image-list'}>
        <ImageList
            variant="quilted"
            cols={3}
            sx={{width: 'auto', height:'100%'}}>
            {images.map((item) => (
                <ImageListItem key={item.src} cols={item.cols || 1} rows={item.rows || 1} sx={{margin: '5px'}}>
                    <Link className={'link'} onClick={() => onClick(item.href)}><img
                        {...srcset(item.src, 180, item.rows, item.cols)}
                        style={{width: "100%", height: "100%"}}
                        alt={item.title}/>
                        <ImageListItemBar
                            className={'itembar'}
                            title={item.title}
                            subtitle={item.subtitle}
                            sx={{height: '30%', 'backgroundColor': 'rgba(255, 255, 255, 0.65)', 'borderRadius':'0 0 20px 20px'}}
                            actionIcon={
                                <IconButton
                                    aria-label={`info about ${item.title}`}
                                >
                                    <ArrowIcon/>
                                </IconButton>
                            }
                        />
                    </Link>
                </ImageListItem>
            ))}
        </ImageList>
    </div>


}