import {FC, useEffect} from "react";
import {APIConfig} from "../config/api";
import useWebSocket from "react-use-websocket";

export interface SocketFrameProps {
    name: string;
    alterable: any;
    indices: number[];
    addIndex: (image_index: number) => void;
    setKey: (key: string) => void;
}

export const SocketFrame: FC<SocketFrameProps> = ({name, alterable, indices, addIndex, setKey}) => {
    /**
     * There are two functions available to us:
     * setKey(string) to set key for images to show.
     * addIndex(number) to show a new image.
     * APIConfig.socket_endpoint
     * */
    const {sendMessage} = useWebSocket(APIConfig.socket_endpoint, {
        onMessage: (event) => {
            const message = JSON.parse(event.data)
            if (message.command === 'save_key') {
                setKey(message.data)
            } else if (message.command === 'show_image'){
                addIndex(Number(message.data))
            }
        }
    })
    useEffect(() => {
        const message = JSON.stringify({command: 'process', alterable, indices, name})
        sendMessage(message)
    }, []);

    return <>
    </>
}