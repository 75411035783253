import './image-checkbox.scss';
import {FC} from "react";

export interface ImageCheckboxProps {
    src: string;
    index: number;
    onClick: (index: number) => void;
    checked: boolean;
}

export const ImageCheckbox: FC<ImageCheckboxProps> = ({src, index, onClick, checked}) => {
    return <>
        <input type="checkbox" id={`checkbox-${index}`} onClick={() => onClick(index)} checked={checked}/>
        <label className='checkbox-label' htmlFor={`checkbox-${index}`}><img src={src} alt={`Image ${index}`}/></label>
    </>

}