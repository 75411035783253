import React, {ChangeEvent, FC} from "react";
import {Box, Grid, Input, Slider, Typography} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import {FormValues} from "./edit-parameters";
import './edit-parameter.scss';

export interface EditParameterProps {
    formOptions: {
        min: number;
        max: number;
        step: number;
    };
    element: keyof FormValues;
    values: FormValues;
    setFormValues: (values: FormValues) => void;
}

export const EditParameter: FC<EditParameterProps> = ({formOptions, element, values, setFormValues}) => {

    const handleIconClick = (sign: number, element: keyof FormValues) => {
        const newValue = Math.max(Math.min(values[element] + sign * formOptions.step, formOptions.max), formOptions.min);
        setFormValues({...values, [element]: newValue})
    }
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, element: keyof FormValues) => {
        setFormValues({...values, [element]: Number(event.target.value),})
    };
    const handleSliderChange = (newValue: number, element: keyof FormValues) => {
        setFormValues({...values, [element]: newValue})
    }
    return <Box className='edit-parameter'>
        <Typography id="input-slider" gutterBottom>
            {element.replace(/([A-Z])/g, ' $1').trim()}
        </Typography>
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Remove onClick={() => handleIconClick(-1, element)}/>
            </Grid>
            <Grid item xs>
                <Slider
                    value={values[element]}
                    onChange={(event, newValue) => handleSliderChange(Number(newValue), element)}
                    aria-labelledby="input-slider"
                    {...formOptions}
                />
            </Grid>
            <Grid item>
                <Add onClick={() => handleIconClick(1, element)}/>
            </Grid>
            <Grid item>
                <Input
                    value={values[element]}
                    size="small"
                    onChange={(event) => handleInputChange(event as ChangeEvent<HTMLInputElement>, element)}
                    inputProps={{
                        ...formOptions,
                        type: 'number',
                    }}
                />
            </Grid>
        </Grid>
    </Box>
}
