import {SvgIconComponent} from "@mui/icons-material/";
import React from "react";
import './header-button.scss';

export interface HeaderButtonProps {
    icon: SvgIconComponent;
    action: () => void;
}

export const HeaderButton = ({icon, action}: HeaderButtonProps) => {
    return <button className={'header-button small'} onClick={() => action()}>
        <div>{React.createElement(icon)}</div>
    </button>
}