import React, {useEffect, useState} from 'react';
import './App.scss';
import logo from './images/logo.png';
import Login from './flows/login';
import Navigate from './flows/navigate';

import {BrowserRouter, Link, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Modal from './components/modal/modal';
import {useAuth} from './hooks/useAuth';
import {Demo} from "./flows/demo";
import {NavigateDemo} from "./flows/navigate-demo";
import {Result} from "./flows/result";

const AnimatedSwitch = () => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const logout = async () => {
        if ((await auth.signOut()).success) {
            navigate('/');
        }
    }
    const [isNext] = useState(true);
    const [wide, setWide] = useState(true);
    return (<>
            <header className="App-header">
                <Link to={'/'}><img src={logo} alt="Nanomation logo"/></Link>
                {auth.isAuthenticated && <button onClick={logout}>Logout</button>}
            </header>
            <Modal wide={wide}>
                <TransitionGroup component={null}>
                        <Routes location={location}>
                            <Route index element={<Login setWide={setWide}/>}/>
                            <Route path="/navigate" element={<Navigate setWide={setWide}/>}/>
                            <Route path="/demo" element={<NavigateDemo setWide={setWide}/>}/>
                            <Route path="/demo/:name" element={<Demo setWide={setWide}/>}/>
                            <Route path="/result/" element={<Result setWide={setWide}/>}/>
                        </Routes>
                </TransitionGroup>
            </Modal>
        </>
    )
};

function App() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    if (width <= 768) {
        return <><h1 className={'basic'}>This website doesn't support mobile yet!</h1>
            <p className={'basic'}>Please visit us on a desktop
                or laptop!</p></>
    }
    return (
        <BrowserRouter>
            <div className="App">
                <AnimatedSwitch/>
            </div>
        </BrowserRouter>
    );
}

export default App;
