import React, {FC} from "react";
import ClearIcon from '@mui/icons-material/Clear';
import './image-popup.scss';

export interface ImagePopupProps {
    url: string;
    alt: string;
    onClose: () => void;
}

export const ImagePopup: FC<ImagePopupProps> = ({url, onClose, alt}) => {
    const handleParentClick = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    return <div className='image-popup' onClick={handleParentClick}>
        <button onClick={() => onClose()}><ClearIcon fontSize={'large'}/></button>
        <img src={url} alt={alt}/>
    </div>

}