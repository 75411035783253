import {useNavigate, useParams} from "react-router-dom";
import {APIConfig} from "../config/api";
import React, {FC, useEffect, useState} from "react";
import ModalContentProps from "../components/modal/modalContent";
import './demo.scss';
import {ImageCheckbox} from "../components/image-checkbox/image-checkbox";
import {BackButton} from "../components/header-button/back-button";
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {HeaderButton} from "../components/header-button/header-button";
import {BallTriangle} from "react-loading-icons";
import {Stack} from "@mui/material";
import {SimpleDialog} from "../components/simple-dialog/simple-dialog";
import {EditParameters} from "../components/edit-parameters/edit-parameters";

export const demos: { [demo_key: string]: number } = {
    sem: 24,
    brightfield: 8

}

export interface DemoProps extends ModalContentProps {
}

export type SelectState = { [key: number]: boolean }
export const Demo: FC<DemoProps> = ({setWide}) => {
    useEffect(() => setWide(true), []);
    const [selectState, setSelectState] = useState({} as SelectState);
    const [modalState, toggleModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({AspectRatio: 0.1, MajorAxis: 50., MinorAxis: 10.});
    const params = useParams() as { name: string }
    const name = params.name;
    if (!demos[name]) {
        return <></>
    }
    const onCheckboxClick = (index: number) => {
        if (selectState[index]) {
            setSelectState({...selectState, [index]: !selectState[index]})
        } else {
            setSelectState({...selectState, [index]: true})
        }
    }
    const itemSelected = () => {
        return Object.values(selectState).filter(val => val).length > 0;
    }
    const toggleSelect = () => {
        const selectAll = !itemSelected();
        const state: SelectState = {}
        for (let i = 0; i < demos[name]; i++) {
            state[i] = selectAll;
        }
        setSelectState(state)
    }
    const onSelectClick = async () => {
        const indices = Object.keys(selectState).filter(key => selectState[Number(key)]).map(key => Number(key));
        navigate(`/result/`, {
            state: {
                name,
                indices,
                alterable: formValues
            }
        });
    }
    const demo_grid = []
    for (let i = 0; i < demos[name]; i++) {
        demo_grid.push({
            src: `${APIConfig.endpoint}/${name}/images/${i}`,
            index: i,
            key: i
        })
    }

    return <>
        {!isLoading ? (<>
            <div className={'header'}>
                <Stack direction="row" alignItems="center" gap={1} className={'left'}>
                    <BackButton></BackButton>
                    <button className={'header-button'} onClick={toggleSelect}>{itemSelected() ? 'Deselect all' : 'Select all'} </button>
                </Stack>
                <Stack className={'center'}>
                    <h2>Choose images</h2>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1} className={'right'}>
                    <HeaderButton icon={SettingsIcon} action={() => toggleModalState(!modalState)}/>
                    <button className={'multi-media'} disabled={!itemSelected()} onClick={onSelectClick}>Run <ArrowForwardIcon/> </button>
                </Stack>
            </div>
            <div className={'demo-grid'}>
                {demo_grid.map(demo => <ImageCheckbox onClick={onCheckboxClick} {...demo}
                                                      checked={selectState[demo.key]}/>)}
            </div>
        </>) : <BallTriangle className={'loading'}/>
        }
        <SimpleDialog isOpen={modalState} toggle={() => toggleModalState(!modalState)}>
            <h2>Configure Parameters</h2>
            <EditParameters setFormValues={setFormValues} values={formValues}/>
            <button onClick={() => toggleModalState(false)}>Save</button>
        </SimpleDialog>
    </>
}