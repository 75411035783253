import React, {FC} from 'react';
import './modal.scss';

interface ModalProps {
    children: React.ReactElement;
    wide?: boolean
}

const Modal: FC<ModalProps> = ({children, wide}) => {
    return (
        <div className={`Modal ${wide ? 'wide' : ''}`}>
            {children}
        </div>
    )
};

export default Modal;
