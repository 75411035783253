import React, {FC, useEffect} from 'react';
import './navigate.scss';
import ModalContentProps from '../components/modal/modalContent';
import PrivateRoute from '../components/private/private';
import {NavigateList} from "../components/navigate-list/navigate-list";
import {BackButton} from "../components/header-button/back-button";

interface NavigateProps extends ModalContentProps {
}

const Navigate: FC<NavigateProps> = ({setWide}) => {
    useEffect(() => setWide(true), []);
    const images = [
        {
            src: "/background1.jpg",
            title: 'Website',
            href: "https://lithotag.com/"
        },
        {
            src: "/background4.jpg",
            title: 'Pitch deck'

        },
        {
            src: "/background3.jpg",
            title: 'Video',
            href: 'https://youtu.be/H4idQBmd-GI'

        },
        {
            src: "/background2.jpg",
            rows: 2,
            cols: 3,
            title: 'Demo',
            subtitle: 'Go straight to the demo',
            href: '/demo',
        },


    ];
    return (
        <PrivateRoute>
            <NavigateList images={images}/>
        </PrivateRoute>)
};

export default Navigate;
